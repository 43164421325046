import React, { Component } from 'react'
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../components/layouts'
import { Container } from '../components/styledComponents';
import { Title } from '../components/styledComponents/products';
import Seo from '../components/seo/page';
import Tabs from '../components/styledComponents/tabs';

const SubTitle = styled.h3`
  margin: 0 0 1rem;
`
const SectionInfo = styled.p`
  margin: 0 0 3rem;
  @media (max-width: 991px) {
    margin-bottom: 1rem;
  }   
`

export default class Bkad extends Component {
    componentDidMount() {
        document.body.style.background = "#fff"
    }

    getData(nodes) {

        const out = {
            node: nodes[0].node,
            childrens: []
        }

        out.childrens = nodes.filter((el, idx) => idx > 0).map(el => {
            return el.node;
        });

        return out;
    }

    render() {
        const { data } = this.props;
        const info = this.getData(data.allMarkdownRemark.edges);
        // console.log(info);
        const node = info.node;

        return (
            <Layout>
                <Seo title={node.frontmatter.title} meta={{
                    description: node.frontmatter.description,
                    keywords: node.frontmatter.keywords,
                    'og:url': 'solutions',
                    'og:description': node.frontmatter.og_description
                }} />
                <Container>
                    <Title>{node.frontmatter.title}</Title>
                    <SubTitle>Умные дороги в городских агломерациях</SubTitle>
                    <SectionInfo>Внедрение интеллектуальных транспортных систем, предусматривающих автоматизацию процессов управления дорожным движением, в городских агломерациях, включающих города с населением свыше 300 тысяч человек.</SectionInfo>
                    <Tabs items={info.childrens} selected="bkad1" />
                </Container>

            </Layout>
        )
    }
}


export const query = graphql`
  query getBkadData {
    allMarkdownRemark (
      filter: {
        frontmatter: {page: {eq: "bkad"}}
      }
      sort: {fields: [frontmatter___main], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            page
            section
            title
            index
            main
            block
            keywords
            description
            og_description
          }
          html
        }
      }
    }
  }
` 